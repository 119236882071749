<template>
  <div class="row">
    <div class="col-xl-6">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Change Photo</h4>
        </div>
        <div class="card-body">
          <form action="">
            <div class="form-row">
              <div class="form-group col-xl-12">
                <div class="media align-items-center mb-3">
                  <img
                    v-if="profile?.orang_avatar_file"
                    class="mr-3 rounded-circle mr-0 mr-sm-3"
                    :src="profile?.orang_avatar_file"
                    width="50"
                    height="50"
                    alt=""
                  />
                  <div v-else class="profile_log">
                    <div class="user">
                      <span class="thumb"><i class="la la-user"></i></span>
                    </div>
                  </div>
                  <div class="media-body">
                    <div>
                      <h4 v-if="profile?.orang_name" class="mb-0">
                        {{ profile?.orang_name }}
                      </h4>
                      <h4 v-else class="mb-0">{{ profile?.orang_nickname }}</h4>
                    </div>
                    <p class="mb-0">Max file size is 20mb</p>
                  </div>
                </div>
                <div class="file-upload-wrapper" :data-text="fileName">
                  <input
                    name="file-upload-field"
                    type="file"
                    class="file-upload-field"
                    value=""
                    @change="updateFileName"
                  />
                </div>
              </div>
              <div class="col-12">
                <button class="btn btn-success waves-effect" disabled>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <changePassword />
    </div>
    <div class="col-xl-12">
      <personal-information :profile="profile" />
    </div>
  </div>
</template>

<script>
import { useProfileStore } from "../../../stores/profile-store";
import app from "@/App.vue";
import personalInformation from "../../../components/profile/PersonalInformation.vue";
import changePassword from "../../../components/profile/ChangePassword.vue";
import validateField from "@/components/ValidateField.vue";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  extends: app,
  components: { validateField, personalInformation, changePassword },

  data() {
    return {
      fullName: "Maria Pascle",
      email: "hello@wavedex.io",
      dob: "",
      presentAddress: "56, Old Street, Brooklyn",
      permanentAddress: "123, Central Square, Brooklyn",
      city: "New York",
      postal: "25481",
      country: "Jordan",
      fileName: "Change Photo",
      profileStore: useProfileStore(),
    };
  },

  validations: {
    fullName: { required, minLength: minLength(4) },
    dob: { required },
    email: { required, email },
    presentAddress: { required },
    permanentAddress: { required },
    city: { required },
    postal: { required },
    country: { required },
  },

  computed: {
    profile() {
      return this.profileStore.profile;
    },
  },

  mounted() {
    this.profileStore.getProfile();
  },

  methods: {
    updateFileName($e) {
      this.fileName = $e.target.value.replace(/.*(\/|\\)/, "");
    },

    submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$router.go(0);
      }
    },
  },
};
</script>

<template>
  <content-body>
    <div class="container-fluid home_wrape">
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-xxl-4">
          <Account />
          <PortfolioSection />
        </div>

        <div class="col-xl-6 col-lg-8 col-xxl-8">
          <!-- profile chart -->
          <ProfileChartSection />
        </div>

        <div class="col-xl-3 col-lg-12 col-xxl-12">
          <!-- flow -->
          <FollowSection />
        </div>
      </div>
    </div>
  </content-body>
</template>

<script>
import PortfolioSection from "../dashboard/crypto/PortfolioSection.vue";
import Account from "../../components/home/Account.vue";
import ProfileChartSection from "../dashboard/crypto/ProfileChartSection.vue";
import FollowSection from "../dashboard/crypto/FollowSection.vue";

export default {
  components: {
    PortfolioSection,
    Account,
    ProfileChartSection,
    FollowSection,
  },
};
</script>

<style scoped>
.home_wrape {
  padding-left: 70px;
}

@media only screen and (max-width: 500px) {
  .home_wrape {
    padding-left: 0;
  }
}
</style>

<template>
  <content-body>
    <div class="row">
      <div class="col-xl-5 col-lg-5 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="buy-sell-widget">
              <b-tabs v-model="activeTab">
                <b-tab title="Withdraw"><WithdrawForm /></b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-7 col-lg-7 col-md-12 flex_img">
        <img src="/images/withdraw.png" alt="withdraw" width="300" />
      </div>
    </div>
    <div>
      <WithdrawHistory />
    </div>
  </content-body>
</template>

<script>
import contentBody from "@/components/dashboard/ContentBody.vue";
import TransferForm from "@/components/transfer/TransferForm.vue";
import DepositTransactionHistory from "@/components/deposit/TransactionHistory.vue";
import app from "@/App.vue";
import WithdrawForm from "../../components/dashboard/withdraw/WithdrawForm.vue";
import WithdrawHistory from "../../components/dashboard/withdraw/WithdrawHistory.vue";

export default {
  extends: app,
  components: {
    contentBody,
    TransferForm,
    WithdrawForm,
    WithdrawHistory,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>

<style scoped>
.flex_img {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 469px) {
  .col-xl-7 img {
    width: 300px;
    margin-bottom: 40px;
  }
}
</style>

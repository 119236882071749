@ -0,0 +1,133 @@
<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Personal Information</h4>
    </div>
    <div class="card-body">
      <form @submit.prevent="submit" name="myform" class="personal_validate">
        <div class="form-row">
          <div class="form-group col-xl-6">
            <label for="email" class="mr-sm-2">Email</label>
            <input
              v-model="profile.orang_email"
              type="email"
              name="email"
              id="email"
              placeholder="hello@wavedex.io"
              class="form-control"
              disabled
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="name" class="mr-sm-2">Name</label>
            <input
              v-model="profile.orang_name"
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              class="form-control"
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="username" class="mr-sm-2">Username</label>
            <input
              v-model="profile.orang_nickname"
              type="text"
              name="username"
              id="username"
              placeholder="Username"
              class="form-control"
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="id_passport" class="mr-sm-2">ID / Passport</label>
            <input
              type="text"
              name="id_passport"
              id="id_passport"
              placeholder="ID / Passport"
              class="form-control"
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="contact" class="mr-sm-2">Contact</label>
            <input
              type="text"
              name="contact"
              id="contact"
              placeholder="Contact"
              class="form-control"
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="address" class="mr-sm-2">Address</label>
            <input
              type="text"
              name="address"
              id="address"
              placeholder="Address"
              class="form-control"
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="referral_code" class="mr-sm-2">Referral Code</label>
            <input
              v-model="profile.orang_refid_shadow"
              type="text"
              name="referral_code"
              id="referral_code"
              placeholder="Referral Code"
              class="form-control"
              disabled
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="upline" class="mr-sm-2">Upline</label>
            <input
              type="text"
              name="upline"
              id="upline"
              placeholder="Upline"
              class="form-control"
              disabled
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="referral_link" class="mr-sm-2">Referral Link</label>
            <input
              :value="`https://www.wavedex.io/auth/sign-up?ref=${profile.orang_refid_shadow}`"
              type="text"
              name="referral_link"
              id="referral_link"
              placeholder="Referral Link"
              class="form-control pr-5"
              disabled
            />
            <copyButton
              :value="`https://www.wavedex.io/auth/sign-up?ref=${profile.orang_refid_shadow}`"
            />
          </div>

          <div class="form-group col-12">
            <button disabled class="btn btn-success pl-5 pr-5">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import copyButton from "../../components/global/CopyButton.vue";

export default {
  components: { copyButton },

  props: {
    profile: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {};
  },
};
</script>

import { ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_API } from "../applibs/http-common";
import { toast } from "../helpers/toast";

const token = Cookies.get("jwt");

export const useProfileStore = defineStore("profile", () => {
  // data state
  const profile = ref(null);
  const address = ref("");
  const balances = ref(null);
  const alllBalance = ref(null);
  const networkBalance = ref(null);
  const totalBalances = ref(null);
  const otherAddress = ref(null);
  const otpDetails = ref(null);
  const qrCode = ref({});
  const walletAddress = ref(null);

  // loading state
  const loading = ref(false);
  const profileLoading = ref(false);
  const qrCodeLoading = ref(false);
  const twoFactorAuthLoading = ref(false);
  const updateAddressLoading = ref(false);

  function sumTotalBalances(balances) {
    const result = balances.reduce(
      (acc, cur) => acc + parseFloat(cur.value),
      0
    );
    totalBalances.value = result;
  }

  async function postFindAddress(address) {
    try {
      loading.value = true;

      const res = await axios({
        method: "post",
        url: `${BASE_API}/profile/find_by_address`,
        data: {
          address,
        },
      });

      if (res.data.success) {
        otherAddress.value = res.data.data.orang;
      } else {
        toast.warning(res.data.data);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      loading.value = false;
    }
  }

  async function postTwoFactorAuthActivate(google_secret) {
    try {
      twoFactorAuthLoading.value = true;

      const res = await axios({
        method: "post",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/profile/two-fa-activate`,
        data: {
          google_secret,
        },
      });

      if (res.data.success) {
        toast.success("Congratulations, you have successfully activated 2FA");

        // berfungsi untuk refresh data
        getProfile();
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      twoFactorAuthLoading.value = false;
    }
  }

  async function postTwoFactorAuthDeactivate(google_secret) {
    try {
      twoFactorAuthLoading.value = true;

      const res = await axios({
        method: "post",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/profile/two-fa-deactivate`,
        data: {
          google_secret,
        },
      });

      if (res.data.success) {
        toast.success("2FA has been successfully turned off for your account");

        // berfungsi untuk refresh data
        getProfile();
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      twoFactorAuthLoading.value = false;
    }
  }

  async function getProfile() {
    try {
      profileLoading.value = true;

      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/profile/`,
      });

      if (res.data.success) {
        profile.value = res.data.data.orang;
      } else {
        logout();
      }
    } catch (err) {
      console.error(err);
    } finally {
      profileLoading.value = false;
    }
  }

  async function walletUpdate(address, google_secret_or_otp) {
    try {
      updateAddressLoading.value = true;

      const res = await axios({
        method: "post",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/wallets/create-or-update`,
        data: {
          address,
          google_secret_or_otp,
        },
      });
      console.log("create wallet: ", res);
      if (res.data.success) {
        walletAddress.value = res.data.data.address;
        toast.success("Your wallet has been successfully updated.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
    } finally {
      updateAddressLoading.value = false;
    }
  }

  async function logout() {
    Cookies.remove("jwt");
    Cookies.remove("usid");
    window.location.href = "/";
  }

  async function getAddress() {
    try {
      loading.value = true;

      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/profile/address`,
      });

      if (res.data.success) {
        address.value = res.data.address;
      }
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  }

  async function getBalance() {
    try {
      loading.value = true;

      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/profile/balance`,
      });

      if (res.data.success) {
        balances.value = res.data.data;

        // berfungsi untuk menggabungkan semua balance wallet yang akan di simpan di totalBalances
        sumTotalBalances(res.data.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  }

  async function getAllBalance() {
    try {
      loading.value = true;

      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/balances/history`,
      });

      if (res.data.success) {
        alllBalance.value = res.data.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  }

  async function getNetworkBalance() {
    try {
      loading.value = true;

      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/network_balances`,
      });

      if (res.data.success) {
        networkBalance.value = res.data.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  }

  async function getOtpDetails() {
    try {
      loading.value = true;

      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/profile/two-fa-qr-img`,
      });

      if (res.data.success) {
        otpDetails.value = {
          qrUrl: res.data.qr_url,
          accountName: res.data.account_name,
          key: res.data.key,
        };
      }
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  }

  async function getTwoFactorAuthQrCode() {
    try {
      qrCodeLoading.value = true;

      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/profile/two-fa-qr-img`,
      });

      if (res.data.success) {
        qrCode.value = {
          qrUrl: res.data.qr_url,
          accountName: res.data.account_name,
          key: res.data.key,
        };
      }
    } catch (err) {
      console.error(err);
    } finally {
      qrCodeLoading.value = false;
    }
  }

  async function getWalletAddress() {
    try {
      loading.value = true;

      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/wallets/my-wallet`,
      });

      if (res.data.success) {
        walletAddress.value = res.data.data.address;
      }
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  }

  return {
    profile,
    address,
    balances,
    alllBalance,
    networkBalance,
    totalBalances,
    otherAddress,
    otpDetails,
    qrCode,
    walletAddress,
    loading,
    profileLoading,
    qrCodeLoading,
    updateAddressLoading,
    twoFactorAuthLoading,
    postFindAddress,
    getProfile,
    walletUpdate,
    getAddress,
    getBalance,
    getAllBalance,
    getNetworkBalance,
    getOtpDetails,
    getTwoFactorAuthQrCode,
    getWalletAddress,
    postTwoFactorAuthActivate,
    postTwoFactorAuthDeactivate,
  };
});

<template>
  <div class="fm_container">
    <div v-if="statusV1 !== null">
      <div class="row">
        <div class="alert alert-info col-12">
          <h3 class="text-dark">Previous Balance Status:</h3>
          <h1 class="text-dark">{{ statusV1 }}</h1>
        </div>
      </div>
    </div>
    <div v-else class="bal_wrap">
      <div class="row text-center">
        <h4 class="col-12">Previous Balance:</h4>
        <h1 class="col-12">{{ totalDeposit }} USD</h1>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <button @click="showModal = true" class="btn btn-primary">
            Update
          </button>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <ModalAlert
        :show="showModal"
        @close="showModal = false"
        :isSuccessUpdate="isSuccessUpdate"
        :loading="isLoading"
        @submit="submit"
      >
        <template #header>
          <h3>Confirm Migration</h3>
        </template>
        <template #body>
          <h5>Note:</h5>
          <p>
            The balance above cannot be changed once the data migration is
            completed. Terms and conditions apply.
          </p>
        </template>
        <template #footer>
          <button @click="showModal = false">Cancel</button>
          <button @click="submit" :disabled="isLoading">
            <span v-if="!isLoading">Update</span>
            <span v-else>Loading...</span>
          </button>
        </template>
      </ModalAlert>
    </Teleport>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";
import ModalAlert from "../modals/ModalAlert.vue";
import { BASE_API } from "../../applibs/http-common";
import { toast } from "../../helpers/toast";

export default {
  components: {
    ModalAlert,
  },
  data() {
    return {
      BASE_API,
      totalDeposit: 0,
      statusV1: null,
      showModal: false,
      isSuccessUpdate: false,
      isLoading: false,
    };
  },
  async mounted() {
    await this.getTotalDepositV1();
  },
  methods: {
    async getTotalDepositV1() {
      try {
        const token = Cookies.get("jwt");
        const res = await axios.get(`${BASE_API}/migrasi/total-deposit-v1`, {
          headers: {
            JWT: token,
          },
        });
        if (res.data.success) {
          this.totalDeposit = res.data.data;
        } else {
          this.statusV1 = res.data.msg;
        }
      } catch (error) {
        console.error("Error fetching total deposit:", error);
        toast.error("Failed to fetch total deposit.");
      }
    },
    async submit() {
      this.isLoading = true;
      try {
        const token = Cookies.get("jwt");
        const res = await axios.post(`${BASE_API}/migrasi/create`, null, {
          headers: {
            JWT: token,
          },
        });

        if (res.data.success) {
          this.isSuccessUpdate = true;
          setTimeout(() => {
            this.$router.go();
          }, 2000);
        } else {
          toast.warning(res.data.msg);
        }
      } catch (error) {
        console.error("Error updating:", error);
        toast.error("Failed to update.");
      } finally {
        this.isLoading = false;
        this.showModal = false;
      }
    },
  },
};
</script>

<style scoped>
.fm_container {
  background-color: #0a1d74;
  border-radius: 10px;
  padding: 70px 20px;
  display: flex;
  justify-content: center;
}
.bal_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

<template>
  <form
    v-if="walletAddress"
    @submit.prevent="submit"
    name="withdrawForm"
    class="withdraw_validate"
  >
    <validate-field title="Wallet" field="wallet" :validations="$v.form.wallet">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text">
            <i class="las la-wallet la-lg"></i>
          </label>
        </div>
        <select
          v-model="form.wallet"
          id="wallet"
          class="form-control"
          name="wallet"
        >
          <option value="">Select</option>
          <option value="B">Wallet B</option>
          <option value="C">Wallet C</option>
        </select>
      </div>
    </validate-field>

    <validate-field
      title="Wallet Address"
      field="walletAddress"
      :custom-errors="customErrors.walletAddress"
      :validations="$v.walletAddress"
    >
      <div class="input-group mb-3">
        <input
          v-model="walletAddress"
          type="text"
          name="walletAddress"
          class="form-control"
          disabled
        />
      </div>
    </validate-field>

    <div class="form-group">
      <label class="mr-sm-2">Balance</label>
      <div class="input-group mb-3">
        <input
          v-model="balance"
          type="text"
          name="balance"
          class="form-control"
          placeholder="1000"
          disabled
        />
      </div>
    </div>

    <validate-field title="Amount" field="amount" :validations="$v.form.amount">
      <div class="input-group mb-3">
        <input
          v-model="form.amount"
          @input="handleInputNumberOnly"
          type="text"
          name="amount"
          class="form-control"
          placeholder="1000"
          autocomplete="off"
        />
      </div>
    </validate-field>

    <button
      type="submit"
      :disabled="loading"
      class="btn btn-success btn-block mt-3"
    >
      <span
        v-if="loading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span v-else>Submit</span>
    </button>
  </form>

  <div v-else>
    <WalletAddress />
  </div>
</template>

<script>
// stores
import { usewithdrawStore } from "../../../stores/withdraw-store";
import { useProfileStore } from "../../../stores/profile-store";

import validateField from "@/components/ValidateField.vue";
import { required } from "vuelidate/lib/validators";
import WalletAddress from "../../wallet/WalletAddress.vue";

export default {
  components: { validateField, WalletAddress },

  data() {
    return {
      form: {
        wallet: "",
        amount: null,
      },
      customErrors: {
        walletAddress: {
          required: "Wallet address required. Please add it in Settings menu",
        },
      },
      balance: "",
      withdrawStore: usewithdrawStore(),
      profileStore: useProfileStore(),
    };
  },

  validations: {
    form: {
      wallet: { required },
      amount: { required },
    },
    walletAddress: { required },
  },

  computed: {
    loading() {
      return this.withdrawStore.createLoading;
    },
    balances() {
      return this.profileStore.balances;
    },
    walletAddress() {
      return this.profileStore.walletAddress;
    },
  },

  watch: {
    "form.wallet"(newVal) {
      this.updateBalance(newVal);
    },
  },

  mounted() {
    this.profileStore.getBalance();
    this.profileStore.getWalletAddress();
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.withdrawStore.postwithdrawCreate(
          this.form.wallet,
          this.form.amount
        );
      }
    },

    updateBalance(walletLabel) {
      const selectedBalance = this.balances.find(
        (balance) => balance.label === walletLabel
      );
      this.balance = selectedBalance ? selectedBalance.value : "";
    },

    handleInputNumberOnly() {
      this.form.amount = this.form.amount.replace(/[^0-9]/g, "");
    },
  },
};
</script>

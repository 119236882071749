<template>
  <div class="sidebar">
    <div class="menu">
      <ul>
        <li>
          <router-link
            to="/dashboard/home"
            v-b-tooltip.hover="responsiveTitleHandler('Home')"
            title="Home"
          >
            <span><i class="la la-igloo"></i></span>
          </router-link>
        </li>
        <!-- hanya orang dengan tipe merchant yang punya transfer menu -->
        <li v-if="myProfile?.merchant_type">
          <router-link
            to="/dashboard/transfer"
            v-b-tooltip.hover="responsiveTitleHandler('Transfer')"
            title="Transfer"
          >
            <span><i class="la la-exchange-alt"></i></span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/dashboard/deposit"
            v-b-tooltip.hover="responsiveTitleHandler('Deposit')"
            title="Deposit"
          >
            <span>
              <span><i class="mdi mdi-cash"></i></span>
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/dashboard/subscriptions"
            v-b-tooltip.hover="responsiveTitleHandler('Subscriptions')"
            title="Subscriptions"
          >
            <span><i class="la la-list"></i></span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/dashboard/withdraw"
            v-b-tooltip.hover="responsiveTitleHandler('Withdraw')"
            title="Withdraw"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 16 16"
            >
              <path
                fill="#9591CC"
                d="m8 16l-2-3h1v-2h2v2h1zm7-15v8H1V1zm1-1H0v10h16z"
              />
              <path
                fill="#9591CC"
                d="M8 2a3 3 0 1 1 0 6h5V7h1V3h-1V2zM5 5a3 3 0 0 1 3-3H3v1H2v4h1v1h5a3 3 0 0 1-3-3"
              />
            </svg>
          </router-link>
        </li>

        <li>
          <router-link
            to="/dashboard/accounts"
            v-b-tooltip.hover="responsiveTitleHandler('Account')"
            title="Account"
          >
            <span><i class="la la-user"></i></span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/dashboard/settings"
            v-b-tooltip.hover="responsiveTitleHandler('Setting')"
            title="Setting"
          >
            <span><i class="la la-tools"></i></span>
          </router-link>
        </li>

        <li>
          <router-link
            to="/dashboard/migration"
            v-b-tooltip.hover="responsiveTitleHandler('Migration')"
            title="Migration"
          >
            <span><i class="la la-forward"></i></span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
// modules
import { computed, onMounted, ref } from "vue";
import { useProfileStore } from "../../stores/profile-store";

const profileStore = useProfileStore();
const myProfile = computed(() => profileStore.profile);
const windowWidth = ref(window.innerWidth);

onMounted(async () => {
  await profileStore.getBalance();
});

const responsiveTitleHandler = (title) => {
  return {
    placement: windowWidth.value <= 500 ? "top" : "right",
    title: title,
  };
};
</script>

<template>
  <div class="footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-6 col-md-6">
          <div class="copy_right">
            Copyright © 2019 Quixlab. All Rights Reserved.
          </div>
        </div>
        <div class="col-xl-6 col-md-6 text-lg-right text-center">
          <div class="social">
            <a
              href="https://www.youtube.com/channel/UChN1goECpg64jlUxSzySiNg?view_as=subscriber"
              ><i class="fa fa-youtube-play"></i
            ></a>
            <a href="https://twitter.com/quixlab"
              ><i class="fa fa-twitter"></i
            ></a>
            <a href="https://www.facebook.com/qxlab"
              ><i class="fa fa-facebook"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import app from "@/App.vue";

export default {
  extends: app,
};
</script>

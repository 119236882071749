<template>
  <div :class="{ nav_wrapper: true, scrolled: isScrolled }">
    <div class="nav_container">
      <div class="logo_wrapper">
        <img src="/images/wavedex-logo.svg" alt="wavedex" width="200" />
      </div>
      <div class="hamburger" @click="toggleMenu">
        <div :class="{ bar1: true, change: menuOpen }"></div>
        <div :class="{ bar2: true, change: menuOpen }"></div>
        <div :class="{ bar3: true, change: menuOpen }"></div>
      </div>
      <div :class="{ list_menu: true, active: menuOpen }">
        <ul>
          <li>
            <router-link to="/">Home</router-link>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <!-- <li>
            <a href="#feature">Feature</a>
          </li> -->
          <li>
            <a href="#roadmap">Roadmap</a>
          </li>
          <li>
            <a href="#news">News</a>
          </li>
          <li>
            <div class="cta_auth">
              <span v-if="!isLogin">
                <router-link to="/auth/sign-in"> Sign / Register </router-link>
              </span>
              <router-link to="/dashboard" v-else>
                <span> Dashboard </span>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      menuOpen: false,
      isScrolled: false,
      isLogin: Cookies.get("jwt") ? true : false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    isLanding() {
      return this.$route.path === "/";
    },
  },
};
</script>

<style scoped>
.nav_wrapper {
  padding: 30px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: background-color 0.3s ease;
  z-index: 999;

  display: flex;
  justify-content: center;
}
.nav_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.nav_wrapper.scrolled {
  background-color: #102987;
  box-shadow: 0 1px 2px rgba(199, 199, 199, 0.1);
  padding: 30px 0;
}
.logo_wrapper {
  flex-shrink: 0;
}
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
  z-index: 2;
}
.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #448ac4;
  transition: all 0.3s ease;
}
.bar1.change {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.bar2.change {
  opacity: 0;
}
.bar3.change {
  transform: rotate(45deg) translate(-5px, -6px);
}
.list_menu {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}
.list_menu ul {
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.cta_auth {
  border: 1px solid #448ac4;
  padding: 4px 7px;
}

@media only screen and (max-width: 1176px) {
  .list_menu ul {
    gap: 20px;
  }
  .logo_wrapper img {
    width: 150px;
  }
}
@media (max-width: 968px) {
  .hamburger {
    display: flex;
  }
  .list_menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #102987;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    z-index: 1;
  }
  .list_menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .list_menu ul {
    flex-direction: column;
    gap: 15px;
  }
  .nav_container {
    width: 95%;
  }
}
</style>

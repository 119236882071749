<template>
  <div class="card profile_chart">
    <!-- <div class="card-header py-0">
      <div class="chart_current_data">
        <h3>{{ currentData }}</h3>
        <p :class="currentDataClass">
          {{ percentageChange }} <span>USD ({{ changeClass }})</span>
        </p>
      </div>
      <div class="duration-option">
        <a
          v-for="(value, name, index) in durationOptions"
          :key="index"
          href="#"
          :id="name"
          :class="{ active: activeDurationOption === index }"
          @click="selectDurationOption(index)"
          >{{ value }}</a
        >
      </div>
    </div> -->

    <!-- <div>
      <crypto-app />
    </div> -->
    <div class="card-body">
      <crypto-app />
      <div class="chart-content text-center">
        <div class="row">
          <div
            v-for="stat in stats"
            :key="stat.label"
            class="col-xl-3 col-sm-6 col-6"
          >
            <div class="chart-stat">
              <p class="mb-1">{{ stat.label }}</p>
              <h5>{{ stat.value }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ApexCharts from "apexcharts";

import CryptoApp from "./CryptoApp.vue";

export default {
  components: { CryptoApp },
  data() {
    return {
      durationOptions: {
        all: "ALL",
        one_month: "1M",
        six_months: "6M",
        one_year: "1Y",
        ytd: "YTD",
      },
      activeDurationOption: 0,
      stats: [
        { label: "24hr Volume", value: "$0.00" },
        { label: "Market Cap", value: "0 USD" },
        { label: "Circulating Supply", value: "0 BTC" },
        { label: "All Time High", value: "0 USD" },
        { label: "Typical hold time", value: "0 days" },
        { label: "Trading activity", value: "0% buy" },
        { label: "Popularity", value: "#0 most held" },
      ],
      currentData: "0 USD",
      percentageChange: "0%",
      currentDataClass: "text-success",
      changeClass: "positive",
    };
  },
  methods: {
    async fetchChartData() {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/coins/bitcoin"
        );
        const data = response.data;
        this.currentData = `${data.market_data.current_price.usd} USD`;
        const percentageChange = data.market_data.price_change_percentage_24h;
        this.percentageChange = `${percentageChange.toFixed(2)}%`;
        this.currentDataClass =
          percentageChange >= 0 ? "text-success" : "text-danger";
        this.changeClass = percentageChange >= 0 ? "positive" : "negative";

        this.stats = [
          {
            label: "24hr Volume",
            value: `$${data.market_data.total_volume.usd.toLocaleString()}`,
          },
          {
            label: "Market Cap",
            value: `${data.market_data.market_cap.usd.toLocaleString()} USD`,
          },
          {
            label: "Circulating Supply",
            value: `${data.market_data.circulating_supply.toLocaleString()} BTC`,
          },
          { label: "All Time High", value: `${data.market_data.ath.usd} USD` },
          { label: "Typical hold time", value: "88 days" },
          { label: "Trading activity", value: "70% buy" },
          { label: "Popularity", value: "#1 most held" },
        ];
        this.initChart(data);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    },
    initChart(data) {
      const options = {
        chart: {
          height: 100,
          type: "line",
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        series: [
          {
            name: "Price",
            data: data.market_data.sparkline_7d.price,
          },
        ],
        dataLabels: { enabled: false },
        stroke: {
          curve: "smooth",
          width: 2,
          colors: ["#7391FF"],
        },
        grid: { show: false },
        tooltip: { enabled: false },
        xaxis: {
          categories: Array.from(
            { length: data.market_data.sparkline_7d.price.length },
            (_, i) => `Day ${i + 1}`
          ),
          axisBorder: { show: false },
          labels: { show: false },
        },
        yaxis: { show: false },
      };

      const chart = new ApexCharts(
        document.querySelector("#timeline-chart"),
        options
      );
      chart.render();
    },
  },
  mounted() {
    this.fetchChartData();
  },
};
</script>

<style scoped>
.duration-option {
  display: flex;
  flex-direction: column;
}
</style>

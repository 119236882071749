<template>
  <div class="container_wrap bg_img" id="news">
    <div class="news_content-wrap">
      <div class="news_head">
        <h2>NEWS</h2>
        <h3>WAVEDEX</h3>
      </div>
      <div class="card_wrapper">
        <div v-for="item in newsData" :key="item.title" class="card_item">
          <div class="card_img">
            <img :src="item.urlToImage" alt="" />
          </div>
          <div class="card_buttom">
            <a :href="item.url" target="_blank">
              <h2>{{ item.title }}</h2>
            </a>
            <div class="author">
              <span>{{ item.author }}</span>
              <span>{{ item.publishedAt }}</span>
            </div>
          </div>
          <a :href="item.url" target="_blank">
            <div class="read_more">
              <span>Read more...</span>
            </div>
          </a>
        </div>
      </div>
      <div class="load_more">
        <p>Pages: {{ currentPage }} of {{ totalPage }}</p>
        <button
          v-if="currentPage !== totalPage"
          :disabled="isLoading"
          @click="handleLoadMore"
        >
          <span v-if="!isLoading">Load more</span>
          <span v-else>Loading...</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { BOT_JO } from "../../applibs/http-common";

export default {
  setup() {
    const newsData = ref([]);
    const currentPage = ref(1);
    const isLoading = ref(false);
    const totalPage = ref(0);

    const getNewsData = async (page = 1) => {
      isLoading.value = true;
      try {
        const response = await axios.get(`${BOT_JO}/news`, {
          params: { page },
        });
        const { articles, totalPages } = response.data;
        if (page === 1) {
          newsData.value = articles;
        } else {
          newsData.value.push(...articles);
        }
        totalPage.value = totalPages;
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const handleLoadMore = () => {
      currentPage.value++;
      getNewsData(currentPage.value);
    };

    // Initial load
    getNewsData(currentPage.value);

    return {
      newsData,
      currentPage,
      isLoading,
      totalPage,
      handleLoadMore,
    };
  },
};
</script>

<style scoped>
.container_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.news_content-wrap {
  width: 80%;
  min-height: 100vh;
  display: flex;
  gap: 40px;
  flex-direction: column;
  justify-content: center;
}
.news_head h2 {
  color: var(--text-base);
  font-size: 70px;
  font-weight: bold;
  margin: 0;
}
.news_head h3 {
  color: var(--text-base);
}
.card_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 40px;
  column-gap: 15px;
  justify-self: center;
}
.card_img {
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
  background-color: #ffff;
}
.card_img img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card_buttom {
  padding: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffff;
}
.card_item h2 {
  margin: 20px 0;
  color: rgb(31, 30, 30);
  font-size: 18px;
  font-weight: bold;
}
.author {
  display: flex;
  justify-content: space-between;
  color: rgb(31, 30, 30);
}
.author span {
  font-size: 12px;
  font-weight: bold;
  color: gray;
}
.read_more {
  margin-top: 30px;
  width: fit-content;
  border: 1px solid #448ac4;
  background: none;
  color: #448ac4;
  padding: 4px 20px;
  cursor: pointer;
}
.read_more:hover {
  background-color: var(--text-base);
  color: #ffff;
}
.load_more {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.load_more p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.load_more button {
  background: #5accfd;
  color: #ffff;
  border: none;
  padding: 4px 20px;
  border-radius: 3px;
}
.load_more button:hover {
  background: #39b6ec;
}

@media only screen and (max-width: 1055px) {
  .card_wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 700px) {
  .news_content-wrap {
    width: 90%;
    height: auto;
  }
  .card_wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .news_head h2 {
    font-size: 20px;
  }
  .news_head p {
    font-size: 16px;
  }
  .card_buttom h2 {
    font-size: 14px;
  }
  .author span {
    font-size: 14px;
  }
  .read_more span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 500px) {
  .card_wrapper {
    row-gap: 50px;
    column-gap: 10px;
    padding-bottom: 30px;
  }
  .card_img {
    height: 150px;
  }
}
</style>

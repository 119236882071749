<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header border-0">
          <h4 class="card-title">Transaction History</h4>
        </div>
        <div class="card-body pt-0">
          <div v-if="loading" class="chart-stat py-5 text-center">
            <span
              class="spinner-border"
              role="status"
              aria-hidden="true"
            ></span>
          </div>

          <div v-else-if="history" class="transaction-table">
            <div class="table-responsive">
              <table
                class="table mb-0 table-responsive-sm text-center text-nowrap"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Amount</th>
                    <th>Amount Net</th>
                    <th>Label</th>
                    <th>Status</th>
                    <th>Address</th>
                    <th>Network</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction of history" :key="transaction.id">
                    <td>
                      <span class="buy-thumb bg-primary mx-auto">{{
                        transaction.id
                      }}</span>
                    </td>
                    <td class="text-success">{{ transaction.amount }}</td>
                    <td class="text-success">{{ transaction.amount_net }}</td>
                    <td class="text-success">{{ transaction.label }}</td>
                    <td>
                      <span
                        v-if="transaction.step === 'COMPLETE'"
                        class="text-success"
                        >{{ transaction.step }}</span
                      >
                      <span
                        v-if="transaction.step === 'WD_CREATED'"
                        class="text-info"
                        >WD CREATED</span
                      >
                      <span
                        v-if="transaction.step === 'CANCEL'"
                        class="text-danger"
                        >CANCEL</span
                      >
                    </td>
                    <td>{{ transaction.address }}</td>
                    <td>{{ transaction.network }}</td>
                    <td>
                      <span class="text-success">
                        {{
                          moment(transaction.created_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <PaginationButtons
              :isLoading="isLoading"
              :isBackLoading="isBackLoading"
              :canGoBack="page > 1"
              :page="page"
              :canLoadMore="history.length >= rows"
              @go-back="handleGoBack"
              @load-more="handleLoadMore"
            />
          </div>

          <div v-else class="chart-stat py-5 text-center">
            <i class="fa fa-search fa-3x mb-3" aria-hidden="true"></i>
            <h4>No data have been recorded</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// modules
import moment from "moment";

// store
import { usewithdrawStore } from "../../../stores/withdraw-store";

// components

import PaginationButtons from "../../global/PaginationButtons.vue";

export default {
  components: {
    PaginationButtons,
  },
  data() {
    return {
      withdrawStore: usewithdrawStore(),
      moment,
      page: 1,
      rows: 5,
      order: "desc",
      isloading: false,
      isBackLoading: false,
    };
  },

  methods: {
    async handleLoadMore() {
      this.isloading = true;
      this.page += 1;
      await this.withdrawStore.getTransactionHistory(
        this.page,
        this.rows,
        this.order
      );
      this.isloading = false;
    },

    async handleGoBack() {
      if (this.page > 1) {
        this.isBackLoading = true;
        this.page -= 1;
        await this.withdrawStore.getTransactionHistory(
          this.page,
          this.rows,
          this.order
        );
        this.isBackLoading = false;
      }
    },
  },

  computed: {
    history() {
      return this.withdrawStore.withdrawHistory;
    },
    loading() {
      return this.withdrawStore.historyLoading;
    },
  },

  mounted() {
    this.withdrawStore.getTransactionHistory(this.page, this.rows, this.order);
  },
};
</script>

<style scoped>
.transaction-table {
  margin-top: 20px;
}
</style>
